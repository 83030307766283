<template>
	<div class="container-fluid">
		<br />
		<div class="col-sm-12 text-right">
			<h1>Mi cuenta</h1>
		</div>
		<br />
		<div class="container">
			<div class="row form-group">
				<div class="col-md-12">
					<h2>Cambiar contraseña</h2>
				</div>
			</div>
			<form @submit.prevent="cambiar_password" class="pass-change mb-4">
				<div class="row form-group">
					<label for="password" class="col-form-label col-sm-4 col-md-3 offset-md-1">Contraseña actual</label>
					<div class="col-sm-8 col-md-6"><input v-model="cuenta.oldpassword" type="password" name="password" id="password" class="form-control"></div>
				</div>
				<div class="row form-group">
					<label for="newpassword" class="col-form-label col-sm-4 col-md-3 offset-md-1">Contraseña nueva</label>
					<div class="col-sm-8 col-md-6"><input v-model="cuenta.password" type="password" name="newpassword" id="newpassword" class="form-control"></div>
				</div>
				<div class="row form-group mb-4">
					<label for="confirm_password" class="col-form-label col-sm-4 col-md-3 offset-md-1">Confirmar contraseña</label>
					<div class="col-sm-8 col-md-6"><input v-model="cuenta.password_confirmation" type="password" name="confirm_password" id="confirm_password" class="form-control"></div>
				</div>
				<div class="row form-group">
					<div class="col-sm-3 offset-sm-9 text-right"><button class="btn principal-btn">Guardar</button></div>
				</div>
			</form>
		</div>
		<div class="container">
			<div class="row form-group">
				<div class="col-md-12">
					<h2>Identidad</h2>
				</div>
			</div>
			<form @submit.prevent="guardar_identidad" class="pass-change mb-4">
				<div class="row form-group">
					<label for="identidad_nombre" class="col-form-label col-sm-4 col-md-3 offset-md-1">Nombre</label>
					<div class="col-sm-8 col-md-6"><input v-model="usuario.nombre" type="text" name="identidad_nombre" id="identidad_nombre" class="form-control"></div>
				</div>
				<div class="row form-group">
					<label for="identidad_curp" class="col-form-label col-sm-4 col-md-3 offset-md-1">CURP</label>
					<div class="col-sm-8 col-md-6"><input v-model="usuario.identidad.curp" type="text" name="identidad_curp" id="identidad_curp" class="form-control"></div>
				</div>
				<div class="row form-group">
					<label for="identidad_rfc" class="col-form-label col-sm-4 col-md-3 offset-md-1">RFC</label>
					<div class="col-sm-8 col-md-6"><input v-model="usuario.identidad.rfc" type="text" name="identidad_rfc" id="identidad_rfc" class="form-control"></div>
				</div>
				<div class="row form-group">
					<label for="odemtodad_sexo" class="col-form-label col-sm-4 col-md-3 offset-md-1">Genero</label>
					<div class="col-sm-8 col-md-6">
						<Radio v-model="usuario.identidad.sexo" name="identidad_genero" id="genero_femenino" value="femenino" label="Femenino" :other="{display:'inline'}" />
						<Radio v-model="usuario.identidad.sexo" name="identidad_genero" id="genero_masculino" value="masculino" label="Masculino" :other="{display:'inline'}" />
					</div>
				</div>
				<div class="row form-group">
					<label for="identidad_nacionalidad" class="col-form-label col-sm-4 col-md-3 offset-md-1">Nacionalidad</label>
					<div class="col-sm-8 col-md-6"><input v-model="usuario.identidad.nacionalidad" type="text" name="identidad_nacionalidad" id="identidad_nacionalidad" class="form-control"></div>
				</div>
				<div class="row form-group">
					<label for="identidad_estado_civil" class="col-form-label col-sm-4 col-md-3 offset-md-1">Estado civil</label>
					<div class="col-sm-8 col-md-6"><input v-model="usuario.identidad.estado_civil" type="text" name="identidad_estado_civil" id="identidad_estado_civil" class="form-control"></div>
				</div>
				<div class="row form-group mb-4">
					<label for="identidad_fecha_nacimiento" class="col-form-label col-sm-4 col-md-3 offset-md-1">Fecha nacimiento</label>
					<div class="col-sm-8 col-md-6"><input v-model="usuario.identidad.fecha_nacimiento" type="date" name="identidad_fecha_nacimiento" id="identidad_fecha_nacimiento" class="form-control"></div>
				</div>
				<div class="row form-group">
					<div class="col-sm-3 offset-sm-9 text-right"><button class="btn principal-btn">Guardar</button></div>
				</div>
			</form>
		</div>
		<div class="container">
			<div class="row form-group">
				<div class="col-md-12">
					<h2>Métodos de contacto</h2>
				</div>
			</div>
			<form @submit.prevent="avatar_cargar_archivo" class="pass-change mb-4">
				<div class="row form-group">
					<div class="col-sm-12 col-md-9 offset-md-1">
						<table class="table-contactos">
							<thead>
								<th>Tipo</th>
								<th>Valor</th>
								<th>Validado</th>
								<th>Principal</th>
								<th>&nbsp;</th>
								<th>&nbsp;</th>
							</thead>
							<tbody>
								<tr v-for="contacto in usuario.contactos" :key="contacto.id">
									<td>{{ contactos_tipos[contacto.tipo] }}</td>
									<td>{{ (contacto.tipo != 'email' ? '+52-' : '')+contacto.valor }}</td>
									<td>{{ contacto.validado == 1 ? 'Validado' : 'Sin validad' }}</td>
									<td>{{ contacto.principal == 1 ? 'Principal' : 'Secundario' }}</td>
									<td>
										<span @click="contacto_nuevo = $helper.clone(contacto)"><i class="gg-pen" title="Editar"></i></span>
									</td>
									<td>
										<span @click="eliminar_contacto_model(contacto)"><i class="gg-close" title="Eliminar"></i></span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<form @submit.prevent="aregar_metodo_contacto">
					<div class="row form-group">
						<div class="col-sm-12 col-md-9 offset-md-1">
							<div class="row">
								<div class="col-sm-3">
									<select v-model="contacto_nuevo.tipo" name="contacto_nuevo_tipo" id="contacto_nuevo_tipo" class="form-control" :disabled="contacto_nuevo.id">
										<option v-for="(texto, tipo, index) in contactos_tipos" :key="index" :value="tipo">{{ texto }}</option>
									</select>
								</div>
								<div class="col-sm-5">
									<div class="input-group">
										<div v-if="contacto_nuevo.tipo != 'email'" class="input-group-prepend"><span class="input-group-text">+52</span></div>
										<input v-model="contacto_nuevo.valor" type="text" name="contacto_nuevo_valor" id="contacto_nuevo_valor" class="form-control" :placeholder="contacto_nuevo.tipo == 'email' ? 'correo@email.com' : 'número a 10 dígitos'">
										<div v-if="contacto_nuevo.tipo != 'telefono' && contacto_nuevo.tipo != 'telefono_oficina'" class="input-group-append">
											<button type="button" class="btn btn-danger" @click="enviar_otp" :disabled="contacto_nuevo.validado == 1">Enviar OTP</button>
										</div>
									</div>
								</div>
								<div class="col-sm-2" style="padding-top: 5px;">
									<BSwitch v-model="contacto_nuevo.principal" id="contacto_primario" name="contacto_primario" textoff="Secundario" texton="Principal" value="1" type="info" />
								</div>
								<div class="col-sm-2">
									<button class="btn secondary-btn">{{ contacto_nuevo.id ? 'Actualizar' : 'Agregar' }}</button>
								</div>
							</div>
						</div>
					</div>
				</form>
				<div class="row form-group">
					<div class="col-sm-3 offset-sm-9 text-right"><button class="btn principal-btn">Guardar</button></div>
				</div>
			</form>
		</div>
		<div class="container">
			<div class="row form-group">
				<h2 class="col-sm-8">Doble autenticación</h2>
				<div class="col-sm-4">
					<div class="row">
						<div class="col-sm-2">Estado: </div>
						<div class="col-sm-2"><BSwitch :value="tmp_two_authentication != 'na'" id="2fa_auth" name="2fa_auth" textoff="Deshabilitado" texton="Habilitado" type="success" @checked="verificar_estado_auth"/></div>
					</div>
				</div>
			</div>
			<div class="row form-group">
				<label for="" class="col-form-label col-sm-4 col-md-3 offset-md-1">Tipo de autenticación</label>
				<div class="col-sm-8 col-md-6">
					<select v-model="tmp_two_authentication" name="twoFaType" id="twoFaType" class="form-control" @change="tipo_auth">
						<option value="na">Selecciona una opción</option>
						<template v-for="cat_option in catalogo_auth.opciones">
						<option v-if="cat_option.activo == 1" :key="cat_option.id" :value="cat_option.valor">{{ cat_option.nombre }}</option>
						</template>
					</select>
				</div>
			</div>
			<template v-if="image_qr">
			<div class="row form-group">
				<div class="col-sm-12">
					Al seleccionar aplicación es necesario que instales en tu teléfono la aplicación de <a :href="url_google" target="__blank">Google Authenticator</a> o <a :href="url_microsoft" target="__blank">Microsoft Authenticator</a>, ambas aplicaciones son compatibles, puedes usar la que mas te guste.
				</div>
			</div>
			<div class="row form-group">
				<label for="" class="col-form-label col-sm-4 col-md-3 offset-md-1">Código QR</label>
				<div class="col-sm-8 col-md-6"><img :src="image_qr" alt="Código QR"></div>
			</div>
			<div class="row form-group">
				<label for="" class="col-form-label col-sm-4 col-md-3 offset-md-1">Código 1</label>
				<div class="col-sm-4">
					<input v-model="qr_code_validate_one" type="text" id="qr_code_one" name="qr_code_one" class="form-control" :disabled="qr_code_validate_one_validate" autocomplete="off">
				</div>
				<div class="col-sm-4"><button class="btn secondary-btn" @click="validar_codigo" :disabled="qr_code_validate_one_validate">Validar código</button></div>
			</div>
			<div v-if="qr_code_validate_one_validate" class="row form-group">
				<label for="" class="col-form-label col-sm-4 col-md-3 offset-md-1">Código 2</label>
				<div class="col-sm-4">
					<input v-model="qr_code_validate_two" type="text" id="qr_code_one" name="qr_code_one" class="form-control" :disabled="qr_code_validate_two_validate" autocomplete="off">
				</div>
				<div class="col-sm-4"><button class="btn secondary-btn" @click="validar_codigo" :disabled="qr_code_validate_two_validate">Validar código</button></div>
			</div>
			<div class="row form-group">
				<div class="col-sm-6">
					Manual para agregar a BienParaBien en Google Authenticator

					<ol>
						<li>Abre la app</li>
						<li>Toca icono de + que se encuentra abajo a la derecha</li>
						<li>Toca la opción que dice "Escanear un código QR"</li>
						<li>Te pedirá permisos para acceder a la cámara, concede los permisos y escanea el código QR que aquí te mostramos</li>
						<li>Una ves agregado el código en la app tendrás que validar 2 códigos diferentes</li>
						<li>Si los 2 códigos son válidos se habrá activado la doble autenticación</li>
					</ol>
				</div>
				<div class="col-sm-6">
					Manual para agregar a BienParaBien Microsoft Authenticator

					<ol>
						<li>Abre la app</li>
						<li>Toca los 3 puntos arriba a la derecha</li>
						<li>Toca la opción que dice "Agregar cuenta"</li>
						<li>Toca la opción que dice "Otra cuenta (Google, Facebook, etc)</li>
						<li>Te pedirá permisos para acceder a la cámara, concede los permisos y escanea el código QR que aquí te mostramos</li>
						<li>Una ves agregado el código en la app tendrás que validar 2 códigos diferentes</li>
						<li>Si los 2 códigos son válidos se habrá activado la doble autenticación</li>
					</ol>
				</div>
			</div>
			</template>
		</div>
		<div class="container">
			<div class="row form-group">
				<div class="col-md-12">
					<h2>Avatar</h2>
				</div>
			</div>
			<form @submit.prevent="avatar_cargar_archivo" class="pass-change mb-4">
				<div class="row form-group mb-4">
					<label for="avatar" class="col-form-label col-sm-4 col-md-3 offset-md-1">Avatar</label>
					<div class="col-sm-8 col-md-6">
						<div class="avatar">
							<img ref="avatar_img" src="" alt="Avatar" class="avatar-container">
							<input ref="avatar_file" type="file" name="avatar_file" id="avatar_file" class="avatar-file" accept=".jpeg,.png,.jpg" @change="avatar_preview">
							<div ref="avatar_loading" class="avatar-loading">Cargando...</div>
							<div class="avatar-control" @click="$refs.avatar_file.click()"><div class="avatar-icon"><i class="gg-pen"></i></div></div>
						</div>
					</div>
				</div>
				<div class="row form-group">
					<div class="col-sm-3 offset-sm-9 text-right"><button class="btn principal-btn">Guardar</button></div>
				</div>
			</form>
		</div>

		<Modal v-if="otp.destinatario" :options="{width: '30vw'}">
			<div class="title">Validar OTP</div>
			<div class="body">
				<div class="row form-group">
					<label for="otp_codigo" class="col-form-label col-sm-2">Código</label>
					<div class="col-sm-10"><input v-model="otp.codigo" type="text" name="otp_codigo" id="otp_codigo" class="form-control"></div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-3 offset-sm-6"><button class="btn secondary-btn" @click="validar_otp">Agregar</button></div>
					<div class="col-sm-3"><button class="btn danger-btn" @click="cancelar_otp">Cancelar</button></div>
				</div>
			</div>
		</Modal>

		<Modal v-if="eliminar_contacto.valor" :options="{width: '30vw',type:'error'}" @close="cancelar_eliminar_contacto">
			<div class="title">Eliminar contacto</div>
			<div class="body">
				<div class="row">
					<div class="col-sm-12 text-center">Eliminarás el contacto <strong>{{ eliminar_contacto.valor }}</strong> <br/>¿Deseas continuar?</div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-3 offset-sm-6" @click="eliminar_contacto_confir"><button class="btn secondary-btn">Eliminar</button></div>
					<div class="col-sm-3">
						<button class="btn danger-btn" @click="cancelar_eliminar_contacto">Cancelar</button>
					</div>
				</div>
			</div>
		</Modal>

		<Modal v-if="twoFaAuthFinish" :options="{type: 'warning'}">
			<div class="title">Doble autenticación activada</div>
			<div class="body">
				<div class="row mt-3 mb-3">
					<div class="col-sm-12 text-center">
						El sistema de autenticación {{ tipo_doble_auth }} ya esta activada, el sistema cerrará sesión para que se apliquen lo cambios,<br>recuerda que de ahora en adelante necesitarás tener a la mano tu celular para poder acceder al sistema.
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-2 offset-sm-10">
						<button class="btn secondary-btn" @click="cerrar_sesion">Continuar</button>
					</div>
				</div>
			</div>
		</Modal>

		<Modal v-if="disable_two_auth" :options="{width: '40vw',type:'warning'}">
			<div class="title">Deshabilitar Doble autenticación</div>
			<div class="body">
				<div class="row">
					<div class="col-sm-12 text-center">
						Estas a punto de deshabilitar el sistema de doble autenticación,<br>¿Deseas continuar?
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-2 offset-sm-8"><button class="btn secondary-btn" @click="deshabilitar_doble_auth">Continuar</button></div>
					<div class="col-sm-2"><button class="btn danger-btn" @click="deshabilitar_doble_auth_cancel">Cancelar</button></div>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script type="text/javascript">
	import { mapState, mapMutations } from 'vuex'
	import Modal from '@/components/Modal'
	import Radio from '@/components/Form/Radio'
	import BSwitch from '@/components/Form/Switch'

	import { isMobile, isAndroid, isIOS, isSafari } from 'mobile-device-detect'

	import api from '@/api/account'

	export default {
		components: {
			Radio, BSwitch, Modal
		}
		,data: function() {
			return {
				cuenta: {
					oldpassword: null
					,password: null
					,password_confirmation: null
				}
				,usuario: {
					nombre: null
					,identidad: {
						curp: null
						,rfc: null
						,sexo: null
						,nacionalidad: null
						,estado_civil: null
						,fecha_nacimiento: null
					}
				}
				,contactos_tipos: {
					email: 'Email'
					,celular: 'Teléfono celular'
					,telefono: 'Teléfono casa'
					,telefono_oficina: 'Teléfono oficina'
				}
				,contacto_nuevo: {
					tipo: 'email'
					,valor: null
					,principal: 0
					,validado: 0
				}
				,otp: {
					destinatario: null
					,codigo: null
				}
				,lista_contactos: {
					nuevos: []
					,editar: []
					,eliminados: []
				}
				,eliminar_contacto: {
					id: null
					,valor: null
				}
				,twoFaAuth: false
				,tmp_two_authentication: 'na'
				,catalogo_auth: []
				,apps: {
					google: {
						android: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=es_MX&gl=US'
						,apple: 'https://apps.apple.com/es/app/google-authenticator/id388497605'
					}
					,microsoft: {
						android: 'https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=es_MX&gl=US'
						,apple: 'https://apps.apple.com/es/app/microsoft-authenticator/id983156458'
					}
				}
				,image_qr: null
				,qr_code_validate_one: null
				,qr_code_validate_one_validate: false
				,qr_code_validate_two: null
				,qr_code_validate_two_validate: false
				,twoFaAuthFinish: false
				,disable_two_auth: false
			}
		}
		,mounted: function() {
			this.cargar_catalogo();

			this.usuario = this.$helper.clone(this.$auth.getUser());
			this.$log.info('usuario', this.$auth.getUser());
			this.$log.info('two_authentication', this.usuario.two_authentication);

			this.tmp_two_authentication = this.usuario.two_authentication != '' ? this.usuario.two_authentication : 'na';

			if (!this.usuario.identidad)
				this.usuario.identidad = {
					curp: null
					,rfc: null
					,sexo: null
					,nacionalidad: null
					,estado_civil: null
					,fecha_nacimiento: null
				}

			this.cargar_avatar();
		}
		,methods: {
			cambiar_password: function() {
				this.$log.info('cuenta: ', this.cuenta)
				let error = [];
				if (!this.cuenta.oldpassword || this.cuenta.oldpassword.length == 0)
					error.push('Tienes que especificar tu contraseña actual');

				if (!this.cuenta.password || this.cuenta.password.length == 0)
					error.push('Tienes que especificar tu nueva contraseña');
				else if (this.cuenta.password.length < 5)
					error.push('Tu nueva contraseña tiene que ser igual o mayor a 5 caracteres');

				if (this.cuenta.password != this.cuenta.password_confirmation)
					error.push('La nueva contraseña y su confirmación no son iguales');

				this.$log.info('error', error);

				if (error.length == 0) {
					api.cambiar_password(this.cuenta)
					.then(res => {
						this.$log.info('res', res);

						this.$notify({
							group: 'notifications'
							,type:'success'
							,title: 'Contraseña actualizada'
							,text: 'La contraseña se a actualizado correctamente'
							,duration: 5000
						});

						this.cuenta = {
							oldpassword: null
							,password: null
							,password_confirmation: null
						}
					}).catch(err => {
						this.$log.info('err', err);
						this.$notify({
							group: 'alert'
							,type:'error'
							,title: 'Error al actualizar contraseña'
							,text: err.response.data.message
							// ,duration: 5000
						})
					})
				}else {
					let msg = error.join('<br/>');
					this.$notify({
						group: 'alert'
						,type:'error'
						,title: 'Error al actualizar tu cotnraseña'
						,text: msg
						// ,duration: 5000
					})
				}
			}
			,guardar_identidad: async function() {
				try {
					let identidad = this.$helper.clone(this.usuario.identidad);
					identidad.nombre = this.usuario.nombre;

					Object.keys(identidad).forEach(key => {
						if (!identidad[key])
							delete identidad[key];
					});

					let res = (await api.actualizar_identidad(identidad)).data;

					this.$store.commit('updateIdentity',res);
					this.$helper.showMessage('Exito!','Se actualizo tus datos de identidad');
				}catch(e) {
					this.$log.info('err',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,cargar_avatar: async function() {
				try {
					let avatar = (await api.cargar_avatar()).data;
					
					let img = this.$refs.avatar_img;
					img.src = 'data:'+avatar.type+';base64,'+avatar.data;
					img.style.opacity = 1;
					this.$refs.avatar_loading.style.display = 'none';
				}catch(e) {
					this.$log.info('err',e);
				}
			}
			,avatar_preview: function() {
				let input = this.$refs.avatar_file;
				let img = this.$refs.avatar_img;

				if (input.files.length == 0)
					return this.$helper.showMessage('Error','Tienes que seleccionar un archivo','error','alert');

				let fileReader = new FileReader();
				fileReader.onload = function(e) {
					img.src = e.target.result;
				}

				fileReader.readAsDataURL(input.files[0]);
			}
			,avatar_cargar_archivo: async function() {
				try {
					if (this.$refs.avatar_file.files.length == 0)
						return this.$helper.showMessage('Error','Tienes que seleccionar una imagen a cargar', 'error', 'alert');

					let form = new FormData();
					form.append('avatar',this.$refs.avatar_file.files[0]);

					await api.subir_avatar(form);

					this.$helper.showMessage('Exito!','Se actualizó tu avatar correctamente');
					this.cargar_avatar();
					this.$store.commit('avatarUpdateStatus','change');
				}catch(e) {
					this.$log.info('err',e);
				}
			}
			,aregar_metodo_contacto: async function() {
				try {
					if (!this.contacto_nuevo.id) {
						this.usuario.contactos = (await api.crear_metodo_contacto(this.contacto_nuevo)).data;
					}else {
						this.usuario.contactos = (await api.editar_metodo_contacto(this.contacto_nuevo)).data;
					}

					this.$store.commit('updateContacts', this.usuario.contactos);

					this.contacto_nuevo = {
						tipo: 'email'
						,valor: null
						,principal: 0
						,validado: 0
					};
				}catch(e) {
					this.$log.error('err',e);
					this.$helper.showAxiosError(e, 'Error');
				}
			}
			,enviar_otp: async function() {
				try {
					if (this.contacto_nuevo.valor == '' || this.contacto_nuevo.valor == null)
						return this.$helper.showMessage('Error','Tienes que especificar un valor','error','alert');

					await api.enviar_otp({destinatario: this.contacto_nuevo.valor});
					this.otp.destinatario = this.contacto_nuevo.valor;
				}catch(e) {
					this.$log.info('err',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,validar_otp: async function() {
				try {
					let res = (await api.validar_otp(this.otp)).data;

					if (res.success) {
						this.contacto_nuevo.validado = 1;
						this.cancelar_otp();
						return this.$helper.showMessage('Exito!','El código fue validado con éxito');
					}

					return this.$helper.showMessage('Error','Error al validar el código: '+res.message,'error','alert');
				}catch(e) {
					this.$log.info('err',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,eliminar_contacto_model: function(contacto) {
				this.eliminar_contacto = {
					id: contacto.id
					,valor: contacto.valor
				}
			}
			,eliminar_contacto_confir: async function() {
				try {
					this.usuario.contactos = (await api.eliminar_metodo_contacto(this.eliminar_contacto.id)).data;

					this.$helper.showMessage('Exito!','Se elimino el método de contacto indicado');
					this.$store.commit('updateContacts', this.usuario.contactos);

					this.cancelar_eliminar_contacto();
				}catch(e) {
					this.$log.error('err',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,cancelar_eliminar_contacto: function() {
				this.eliminar_contacto = {
					id: null
					,valor: null
				}
			}
			,cancelar_otp: function() {
				this.otp = {
					destinatario: null
					,codigo: null
				}
			}
			,cargar_catalogo: async function() {
				try {
					this.catalogo_auth = (await api.obtener_catalogo('doble_autenticacion')).data
				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,tipo_auth: async function() {
				this.$log.info('tipo_auth: '+this.tmp_two_authentication);

				try {
					switch(this.tmp_two_authentication) {
						case '1':
						case '2':
							let res = (await api.activar_doble_auth_otp({tipo: this.tmp_two_authentication})).data;
							this.$log.info('res',res);
							if (res.success)
								this.twoFaAuthFinish = true;
							break;
						case '4':
							this.image_qr = (await api.obtener_qr_tfa()).data;
							break;
					}
				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,validar_codigo: async function() {
				try {
					// qr_code_validate_one
					// qr_code_validate_two
					let res = null;
					if (!this.qr_code_validate_one_validate) {
					 	res = (await api.validar_codigo_tfa({codigo: this.qr_code_validate_one, check: 1})).data;

					 	this.qr_code_validate_one_validate = res.is_valid;
					}else {
						res = (await api.validar_codigo_tfa({codigo: this.qr_code_validate_two, check: 2})).data;

					 	this.qr_code_validate_two_validate = res.is_valid;

					 	if (res.success) {
					 		this.image_qr = null;
					 		this.twoFaAuthFinish = true;
					 	}
					}

					this.$log.info('res', res);
				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,cerrar_sesion: function() {
				this.removeCredentials();
				this.removeMenu();

				this.$router.push({name: 'login'});
			}
			,verificar_estado_auth: function(val) {
				this.$log.info('val', val);

				if (val == 0)
					this.disable_two_auth = true;
			}
			,deshabilitar_doble_auth: async function() {
				try {
					let res = (await api.deshabilitar_doble_auth()).data;
					this.$log.info('res', res);

					this.cerrar_sesion();
				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e, 'Error');
				}
			}
			,deshabilitar_doble_auth_cancel: function() {
				this.disable_two_auth = false;
				// this.tmp_two_authentication = this.usuario.two_authentication != '' ? this.usuario.two_authentication : 'na';
				document.getElementById('2fa_auth').click();
			}
			,...mapMutations(['removeCredentials','removeMenu'])
		}
		,computed: {
			url_google: function() {
				let url = '';
				if (isMobile) {
					if (isAndroid)
						url = this.apps.google.android;
					else
						url = this.apps.google.apple;
				}else {
					if (isSafari)
						url = this.apps.microsoft.apple;
					else
						url = this.apps.microsoft.android;
				}

				return url;
			}
			,url_microsoft: function() {
				let url = '';
				if (isMobile) {
					if (isAndroid)
						url = this.apps.microsoft.android;
					else
						url = this.apps.microsoft.apple;
				}else {
					if (isSafari)
						url = this.apps.microsoft.apple;
					else
						url = this.apps.microsoft.android;
				}

				return url;
			}
			,tipo_doble_auth: function() {
				switch(this.tmp_two_authentication) {
					case '1':
						return 'por OTP (SMS)';
						break
					case '2':
						return 'por OTP (Email)';
						break;
					case '4':
						return 'por aplicación (2FA)';
						break;
				}
			}
		}
	}
</script>

<style lang="scss">

	.container {
		margin-bottom: 60px
	}

	.table-contactos {
		width: 100%;

		thead {
			background-color: #333333;

			th {
				padding: 10px 15px;
			}
		}

		tbody {
			tr {
				td {
					padding: 10px 15px;

					span {
						width: 20px;
						height:20px;
						padding-top: 10px;
						display: inline-block;
					}
				}
			}

			tr:nth-child(2n+0) {
				background-color: #E6E6E6;
			}
		}
	}

	.avatar {
		width: 100px !important;
		height: 100px !important;
		position: relative;

		.avatar-container {
			width: 100%;
			border-radius: 50%;
			opacity: 0;
		}

		.avatar-file {
			display: none;
		}

		.avatar-control {
			width: 100%;
			height: 100%;
			border: solid 2px #55d775;
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;

			.avatar-icon {
				width: 28px;
				height: 28px;
				background-color: #55d775;
				position: absolute;
				bottom: 0;
				right: 0;

				.gg-pen {
					margin-top: 12px;
					margin-left: 10px;
					color: #fff;
				}
			}
		}

		.avatar-control:hover {
			opacity: 1;
		}

		.avatar-loading {
			transform: translate(-50%, -50%);
			position: absolute;
			top: 50%;
			left: 50%;
		}
	}
</style>